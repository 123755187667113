export function slider(){
  const mvSlider = document.querySelector('.mv_slider');

  const swiper = new Swiper('.mv_slider', {
    // loop: true,
    effect: "fade",
    noSwipingClass: 'swiper-slide',
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 2000,
  });
}
