export function news(){
  $(function(){

    //▼初期表示
    $('.archiveList_cmn_btn').eq(0).parents('.archiveList_cmn').toggleClass('active');
    $('.archiveList_cmn_btn').eq(0).parents('.archiveList_cmn').find('.archiveList_cmn_list').slideToggle(0);

    //▼年度の開閉
    $('.archiveList_cmn_btn').click(function(){
      $(this).parents('.archiveList_cmn').find('.archiveList_cmn_list').slideToggle(600);
      $(this).parents('.archiveList_cmn').toggleClass('active');
    });

    //▼ページャー設定
    var pager = $(".pager");

    for( var idx=0; idx<pager.length; idx++ ){

      var set_row = parseInt($(".pager").eq(idx).find(".set_row").val());
      var max_row = parseInt($(".pager").eq(idx).find(".max_row").val());

      if( max_row > set_row){
        //▼指定件数以上
        var page = Math.ceil(max_row / set_row);
        var page_html = "";

        for( var idy=1; idy<=page; idy++ ){
          page_html+="<li class=\"page_number\" data-page_number=\""+idy+"\">"+idy+"</li>";
        }
        $(".pager ul").eq(idx).html(page_html);

      }else{
        //▼無処理
        $(".pager ul").eq(idx).html("1");
      }
    }

    $(".page_number").on("click",function(){

      //▼退避
      var pager_val = $(this).parents(".archiveList_cmn_list_cmn");
      
      //▼削除
      $(this).parents(".archiveList_cmn_list").find(".archiveList_cmn_list_cmn:not(:last-child)").remove();
      
      //▼再配置
      var year = $(this).parents('.archiveList_cmn_list_cmn').find(".year").val();
      var set_row = $(this).parents('.archiveList_cmn_list_cmn').find(".set_row").val();
      var nxt_row = $(this).data("page_number");
      var objList = $(this).parents(".archiveList_cmn_list");

      //▼ajax処理
      $.ajax({
        url: ajaxurl,
        type: "POST",
        data: {
          'action' : 'ajax_news',
          'posts_per_page': set_row,
          'paged'   : nxt_row,
          'year'    : year
        }

      }).done(function (data) {
        //console.log(data);
        objList.prepend(data);
      });

    });
  });
}
