import { news } from "./modules/news";
import { mailmagazine } from "./modules/mailmagazine";
import { business } from "./modules/business";
import { slider } from "./modules/slider";
// import { smoothScroll } from './modules/smoothscroll';
import { scrollHint } from "./modules/scrollHint";

scrollHint();

$(function () {
  // URLのアンカー（#以降の部分）を取得
  var urlHash = location.hash;
  // URLにアンカーが存在する場合
  if (urlHash) {
    //var offset = $('#pageHeader').height();
    var offset = 84; //何px下に下げるかの数値
    var top = $(urlHash).offset().top - offset;
    animateoffset(top); //実際にスクロールさせる関数animateoffsetを実行
    // function animateoffset(top){
    // 	$('html,body').animate({scrollTop:top},1000);//引数topの値までスクロールさせる
    // }
  }
  $('.innerLink[href^="#"]').click(function () {
    smooth(this);
    return false;
  });

  $(".pageTopBtn a").click(function () {
    animateoffset(0);
    return false;
  });

  //smoothScroll
  function smooth(target) {
    var href = $(target).attr("href"); //引数targetが持つhref値を取得
    //var offset = $('#pageHeader').height();
    var offset = 84; //何px下に下げるかの数値
    var top = $(href).offset().top - offset;
    animateoffset(top); //実際にスクロールさせる関数animateoffsetを実行
  }
  function animateoffset(top) {
    $("html,body").animate({ scrollTop: top }, 1000); //引数topの値までスクロールさせる
  }

  //smoothScroll

  // $('.navDown').hover(()=>{
  // 	const inNav = $(this)
  // 	inNav.next().slideToggle();
  // 	// $('.global--nav_list_cmn_list').slideToggle();
  // });
  // smoothScroll();

  if ($(".type-news").length > 0) {
    news();
  }
  if ($(".type-mailmagazine").length > 0) {
    mailmagazine();
  }
  if ($(".pageBusiness").length) {
    business();
  }
  if ($(".mv_slider").length) {
    slider();
  }

  // for(let i = 0; i < navCmn.length; i++){
  // 	navCmn[i].addEventListener('click', function(){
  // 		pageHeader.classList.toggle('open');
  // 	});
  // }

  //==================================================================================================================
  //▼ Header event
  //------------------------------------------------------------------------------------------------------------------
  //================================================
  //▼ nav open
  //------------------------------------------------
  //▼ header Element get
  const pageHeader = document.querySelector("#pageHeader");
  const navOpen = document.querySelector("#navOpen");
  // const navDown = document.querySelectorAll('.navDown');
  const navDown = $(".navDown .global--nav_list_cmn_inner");
  // const navDowninner = $('.global--nav_list_cmn_list');
  // const navCmn = document.querySelectorAll('.global--nav_list');

  const windowH = window.innerHeight;

  let winW = window.innerWidth;
  if (winW < 800) {
    //▼ nav buttton clickEvent classAdd
    $(".global--nav_list").hide();
    navOpen.addEventListener("click", function () {
      pageHeader.classList.toggle("open");
      $(".global--nav_list").slideToggle(300);
    });

    // $('.global--nav_list_cmn_list').hide();
    for (let i = 0; i < navDown.length; i++) {
      navDown[i].addEventListener("click", function () {
        $(this).next().slideToggle(500);
      });
    }
  } else {
    //▼ window.pageYOffset is over window.innerHeight event classAdd
    window.addEventListener("scroll", function () {
      if (windowH < scrollY) {
        pageHeader.classList.add("active");
      } else {
        pageHeader.classList.remove("active");
      }
    });
  }
});

//
// let scrollY = '';
// window.addEventListener('scroll', function(){
// 	scrollY = window.pageYOffset;
// });

//==================================================================================================================
//▼ base scroll animations
//------------------------------------------------------------------------------------------------------------------
// $(function(){
// var controller = new ScrollMagic.Controller();
//   $('.js-scroll').each(function(){
//     var obj = this;
//     var $obj = $(this);
//       var scene = new ScrollMagic.Scene({triggerElement: this, triggerHook: .7,})
//         .on('enter',function(){
//           $obj.addClass('animated');
//         })
// //        .addIndicators()
//         .addTo(controller);
//   });
//   $('.js-fade-in-up').each(function(){
//     var obj = this;
//     var $obj = $(this);
//       var scene = new ScrollMagic.Scene({triggerElement: this, triggerHook: .6,})
//         .on('enter',function(){
//           $obj.addClass('animated');
//         })
// //        .addIndicators()
//         .addTo(controller);
//   });
//   $('.js-fade-in').each(function(){
//     var obj = this;
//     var $obj = $(this);
//       var scene = new ScrollMagic.Scene({triggerElement: this, triggerHook: .7,})
//         .on('enter',function(){
//           $obj.addClass('animated');
//         })
// //        .addIndicators()
//         .addTo(controller);
//   });
//   $('.js-mask').each(function(){
//     var obj = this;
//     var $obj = $(this);
//       var scene = new ScrollMagic.Scene({triggerElement: this, triggerHook: .7,})
//         .on('enter',function(){
//           $obj.addClass('animated');
//         })
// //        .addIndicators()
//         .addTo(controller);
//   });
// });
