export function business(){
  $(function(){
    const controller = new ScrollMagic.Controller();
    $('.lineupAnimation').each(function(){
      var obj = this;
      var $obj = $(this);
        var scene = new ScrollMagic.Scene({triggerElement: this, triggerHook: .7,})
          .on('enter',function(){
            $obj.addClass('animated');
          })
          .addTo(controller);
    });
  });
}
