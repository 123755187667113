import ScrollHint from "scroll-hint";

export function scrollHint() {
  new ScrollHint(".js-scrollable", {
    suggestiveShadow: true,
    i18n: {
      scrollable: "スクロールできます",
    },
  });
}
